import axios from 'axios';
import { $on, qs, qsa } from './utils';

const formData = new FormData(); 

$on(qs('#queryForm'), 'submit', function(event) {
    event.stopImmediatePropagation();
    event.preventDefault();

    qsa('.query-item').forEach(el => {
      formData.append(el.name, el.value);
    });

    axios.post(`${process.env.BASE_URL}/v1/inquiry/hourmane`, formData)
    .then(response => {
      if (response.data.result === 'ok') { 
         qs('#queryForm').reset();
         window.location.href = '/inquiry-complete'; 
      }
    })
    .catch(error => {
      throw(error.message);
    });
});
